<template>
  <div eagle-progress-loading v-if="status" :style="loadingStyle">
    <v-progress-linear
      indeterminate
      color="primary"
    ></v-progress-linear>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  computed: {
    ...mapGetters({
      status: 'loading/progress',
    }),
    loadingStyle() {
      return {
        zIndex: window.$zIndexConfig.loading,
      }
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped>
div[eagle-progress-loading]
  width: 100vw
  position: fixed
</style>